header,footer{
    box-shadow: 0px 7px 18px 3px rgba(0, 0, 0, 0.07);
    background-color: white;
    position: fixed;
    padding: 7px 0;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    color: var(--black);
    a{
        color: var(--black);
    }
    .row{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
footer{
    position: static;
}

.header{
    &__logo{
        display: flex;
        align-items: center;
        img{
            width: 58px;
            height: 60px;
            margin-right: 14px;
        }
    }
    &__address{
        display: flex;
        align-items: center;
        font-style: normal;
        div{
            display: flex;
            align-items: flex-end;
            margin-left: 40px;
            flex-direction: column;
            a{
                margin-bottom: 13px;
                transition: .3s ease;
                &:last-child{
                margin-bottom: 0;
                }
                &:hover{
                    color: var(--blue);
                }
            }
        }
    }
}

.navigation{
    &__list{
        list-style: none;
        padding: 0;
        display: flex;
        align-items: center;
    }
    &__link{
        transition: .3s ease;
    }
    &__item{
        margin-right: 15px;
        position: relative;
        z-index: 2;
        &.current{
            .navigation__link{
                color: var(--blue);
                font-weight: 700;
            }
        }
        &:last-child{
            margin-right: 0;
        }
        &:hover{
            .navigation__link{
                color: var(--blue);
                // font-weight: 700;
            }
        }
    }
    &__bonus{
        background-color: var(--blue);
        color: white;
        font-weight: 700;
        width: 17px;
        height: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        position: absolute;
        top: -10px;
        right: -13px;
        z-index: -1;
        margin: 0;
        padding: 0;
        font-size: 14px;
    }
}