.service{
    // background: #fafafa;
    .form{
        margin: 60px 0;
    }
    
    .buttons{
        margin-top: 40px;
        display: flex;
        justify-content: center; 
        a{ 
            width: 325px;
            margin-right: 20px;
            display: block;
            text-align: center;
            &:last-child{
                margin-right: 0;
            }
        } 
    }

    &__list{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        list-style: none;
        padding: 0;
    }
    &__item{
        height: 245px;
        position: relative;
        overflow: hidden;
        &:hover{
            .service__overlay{
                top: 0;
            }
            .service__img{
                filter: blur(4px);
            }
            .service__heading{
                left: 110%;
            }
        }
    }
    &__img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: .3s ease;
        filter: blur(0);
        object-fit: cover;
    }
    &__heading{
        position: absolute;
        width: 100%;
        padding: 20px;
        bottom: 0;
        left: 0;
        background-color: var(--blue);
        margin: 0;
        transition: .3s ease;
    }
    &__overlay{
        background-color: var(--bluea);
        position: absolute;
        top: 110%;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        padding: 20px;
        transition: .3s ease;
        .button{
            margin-top: 30px;
            margin-left: auto;
            margin-right: auto;
            display: block;
            text-align: center;
            width: 200px;
        }
    }
}