.price{
    &__list{
        display: flex;
        padding: 0;
        margin: 80px 0 0;
        list-style: none;
        flex-direction: column;
    }
    &__item{
        background-color: var(--grey);
        border: var(--border);
        margin: 0 0 5px;
        padding-left: 20px;
    }
    &__data{
        display: flex;
        align-items: center;
        .button{
            // margin-left: auto;
            height: 100%;
        }
        .text{
            margin: 0;
        }

    }
    &__description{
        display: none;
        font-size: 12px;
        max-width: 60%;
    }
    &__show{
        width: 27px;
        height: 27px;
        background-color: var(--blue);
        border-radius: 100%;
        font-weight: 700;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        color: white;
        margin-left: 15px;
    }
    &__price.text{
        margin-left: auto;
        margin-right: 20px; 
        font-weight: 700;
    }
}
article{
    padding: 100px 0 80px;
}
.article{
    padding-top: 80px;
    &__content{
        p{
            max-width: 80%;
            line-height: 1.5;
        }
        ul{
            list-style: none;
            padding: 0 0 0 25px;
            max-width: 40%;
        }
        li{
            margin-bottom: 10px;
            position: relative;
            &::before{
                content: '';
                width: 13px;
                height: 13px;
                border-radius: 100%;
                background-color: var(--blue);
                position: absolute;
                top: 2px;
                left: -20px;
            }
        }
    }
}