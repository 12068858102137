:root {
    --black: #3f3f3f;
    --blue: #002993;
    --yellow: #EEDC34;
    --bluea: #002a939f;
    --grey:#F4F4F4;
    --border:1px solid rgba(63, 63, 63, 0.1);
}

* {
    box-sizing: border-box;
}
.page__heading{
    color: white !important;
    
    &::after{
        display: none;
    }
}
header{
    a{
        color: var(--black);
    }
}
.overlay{
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    z-index: 20;
    background-color: rgba(0, 41, 147, 0.71);
    width: 100vw;
    height: 100vh;
}
.noafter{
    &::after{
        display: none;
    }
}
.close{
    position: absolute;
    top: 20px;
    right: 20px;
    background-image: url(../img/close.svg);
    width: 19px;
    height: 19px;
    border: none;
    margin: none;
    padding: 0;
    background-color: transparent;
    z-index: 2;
}
.innerPage{
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 105vw;
    top: 0;
    z-index: 36;
    background-color: white;
    padding: 0px 0 0 0;
    transition: .3s ease;
    overflow-x: hidden;
    overflow-y: auto;
    &.active{
        left: 0;
    }
    .close{
        top: 80px;
        right: 80px;
    }
}
.modal{
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 40px;
    border-radius: 20px;
    background-color: white;
    z-index: 21;
    flex-direction: column;
    span{
        display: block;
        font-weight: 400;
    }
    .text{
        text-align: center;
    }
    .heading{
        text-align: center;
        margin: 0 0 15px;
        p{
            margin: 0;
        }
    }
}
body {
    font-family: 'Montserrat', sans-serif;
    color: var(--black);
}
.page{
    position: relative;
    background-image: url(../img-compressed/servicepage.jpg);
    background-size: cover;
    padding: 75px 0;
    .text{
        max-width: 50%;
        margin-top: -20px;
        z-index: 2;
        position: relative;
    }
}
.burger{
    display: none;
    cursor: pointer;
    // display: flex;
    &::before,&::after{
        content: '';
        width: 40px;
        height: 4px;
        background-color: var(--blue);
        border-radius: 5px;
        margin: 5px 0;
        display: block;
        
    }
}
body,
html {
    width: 100vw;
    max-width: 100vw;
    overflow-x: hidden;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

body {
    padding-top: 43px;
}

section {
    padding: 80px 0;
}
.text-c{
    text-align: center;
}
.text {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.3;
    &::before{
        background-size: contain;
    }
    &.geo {
        position: relative;

        &::before {
            content: '';
            width: 20px;
            height: 20px;
            position: absolute;
            right: calc(100% + 5px);
            background-image: url(../img/geo.svg);
        }
    }

    &.mail {
        position: relative;

        &::before {
            content: '';
            width: 20px;
            height: 20px;
            position: absolute;
            right: calc(100% + 5px);
            background-image: url(../img/mail.svg);
        }
    }

    &.phone {
        position: relative;

        &::before {
            content: '';
            width: 20px;
            height: 20px;
            position: absolute;
            right: calc(100% + 5px);
            background-image: url(../img/phone.svg);
        }
    }
    &.vk {
        position: relative;

        &::before {
            content: '';
            width: 20px;
            height: 20px;
            position: absolute;
            right: calc(100% + 5px);
            background-image: url(../img/vk.svg);
        }
    }
    &.wa {
        position: relative;

        &::before {
            content: '';
            width: 20px;
            height: 20px;
            position: absolute;
            right: calc(100% + 5px);
            background-image: url(../img/wa.svg);
        }
    }
    &.gmail {
        position: relative;

        &::before {
            content: '';
            width: 20px;
            height: 20px;
            position: absolute;
            right: calc(100% + 5px);
            background-image: url(../img/gmail.svg);
        }
    }
    &.inst {
        position: relative;

        &::before {
            content: '';
            width: 20px;
            height: 20px;
            position: absolute;
            right: calc(100% + 5px);
            background-image: url(../img/ig.svg);
        }
    }

    &--big {
        font-size: 18px;
    }

    &--min {
        font-size: 14px;
    }

    &--mini {
        font-size: 12px;
        opacity: .5;
    }
}

.heading {
    color: var(--black);
    font-weight: 700;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        width: 202px;
        height: 4px;
        background-color: var(--black);
        bottom: -20px;
        left: 0;
    }
    &--mini{
        font-size: 14px;
        &::after{
            display: none;
        }
    }
    &--big {
        margin: 0 0 45px;
        line-height: 1.4;
        font-size: 36px;
    }

    &--min {
        &::after {
            display: none;
            
        }
        font-size: 18px;
        margin: 0 0 16px;
    }
    &--sect{
        font-size: 24px;
        margin-bottom: 70px;
        &.white{
            margin: 0 0 10px;
            &::after{
                display: none;
                
            }
        }
    }
}

.white {
    color: white;
}

.button {
    padding: 16px 40px;
    color: var(--black);
    width: auto;
    border: none;
    font-weight: 700;
    display: inline-block;
    transition: .3s ease;

    &--blue {
        color: white;
        background-color: var(--blue);
        box-shadow: 0px 0px 6px 4px rgba(0, 41, 147, 0.24);
        &:hover{
            box-shadow: 0px 0px 10px 8px rgba(0, 41, 147, 0.24);
        }
    }

    &--yellow {
        background-color: var(--yellow);
        box-shadow: 0px 0px 6px 4px rgba(238, 220, 52, 0.29);
        &:hover{
            box-shadow: 0px 0px 10px 8px rgba(238, 220, 52, 0.24);
        }
    }

    &--icon {
        padding-right: 60px;
        position: relative;
        margin-top: 60px;

        &::after {
            content: '';
            width: 90px;
            height: 72px;
            background-image: url(../img-compressed/flag.png);
            position: absolute;
            right: -45px;
            bottom: -9px;
        }
    }
}

a:link,
a:visited {
    text-decoration: none;
}

button,a{
    cursor: pointer;
}

.mobile-menu{
    display: none;
    position: fixed;
    top: 0;
    left: 110vh;
    background-color: #fff;
    width: 100vw;
    height: 100vh; 
    z-index: 100;
}

@media screen and(max-width:750px){
    .mobile-menu{
        display: block;
        transition: .3s ease;
        &.active{
            left: 0;
        }
        .container{
            display: flex;
            align-items: center;
            flex-direction: column;
            position: absolute;
            top:50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
        ul{
            flex-direction: column;
            li{
                margin: 0 0 10px 0;
                a{
                    font-size: 18px;
                    color: var(--black);
                }
            }
        }
        address{
            div{
                margin: 0 0 20px;
            align-items: center;
            }
        }
        button{
            white-space: nowrap;
        }
    }
}