.form{
    padding: 50px 0;
    background-image: url(../img-compressed/form.jpg);
    background-size: cover;
    position: relative;
    overflow: hidden;
    &__img{
        position: absolute;
        right: 0;
        bottom: -73px;
    }
    &__form{
        width: 50%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 25px;
        grid-row-gap: 25px;
        margin-top: 40px;
        .text--mini{
            color: white;
        }
        &--modal{
            width: 100% !important;
            grid-template-columns: 1fr !important;
            input{
                background-color: var(--grey);
            }
            .form__label-text{
                color: var(--black) !important;
            }
            .text--mini{
                color: var(--black);
            }
        }
    }
    &__input{
        width: 100%;
        height: 55px;
        border: none;
        outline: none;
        padding-left: 20px;
        &:focus,:placeholder-shown{
            &+p{
                top: -20px;
                color: white;
                transform: translateY(0);
                font-size: 10px;
            }
        }
        &:not(:placeholder-shown){
            &+p{
                top: -20px;
                color: white;
                transform: translateY(0);
                font-size: 10px;
            }
        }
    }
    &__label{
        position: relative;
        &-text{
            position: absolute;
            top: 50%;
            left: 20px;
            transform: translateY(-50%);
            margin: 0;
            transition: .3s ease;
        }
    }
}

.modal{
    img{
        display: block;
        margin: 10px auto;
        width: 173px;
        height: 173px;
    }
}