.portfolio {
    &__list {
        grid-template-columns: repeat(5, 1fr);
        display: grid;
        padding: 0;
        margin: 0;
        .service__heading{
            background-color: transparent;
        }
    }
    &__link{
        background-color: var(--yellow);
        color: blue;
        font-size: 14px;
        padding: 3px 11px;
        border-radius: 8px;
        margin-right: 5px;
        margin-bottom: 5px;
        white-space: nowrap;
        display: inline-block;
        transition: .3s ease;
        &.disabled{
            color: var(--black);
            background-color: white;
            border: 1px solid #3f3f3f9c;
        }
    }
    .text-c{
        margin-top: 40px; 
    }
}

.filter{
    &__container{
        display: flex;
        margin-bottom: 40px;
        h2{
            margin-right: 20px;
            flex-shrink: 0;
        }
        p{
            margin: 0;
        }
    }
}

.slider{
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: flex-end;
    img{
        display: none;
        width: 500px;
        height: 345px;
        object-fit: cover;
        &.active{
            display: block;
        }
    }
    &__button{
        width: 65px;
        height: 65px;
        background-color: var(--grey);
        border: none;
        display: block;
        background-image: url(../img/arrow-l.svg);
        // background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        transform: translate(0,0);
        transition: .3s ease;
        &:hover{
            transform: translate(-5px,5px);
        }
        &.next{
            transform: translate(0,0) rotate(180deg);
            &:hover{
                transform: translate(5px,5px) rotate(180deg);
            }

        }
    }
    &__controll{
        display: flex;
    }
}