.adv {
    .flex{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__text {
        max-width: 60%;
        .text{
            line-height: 1.5;
        }
    }

    &__list {
        display: flex;
        justify-content: space-between;
        list-style: none;
        padding: 0;
        margin-top: 50px;

    }

    &__item {
        margin-right: 30px;

        &:last-child {
            margin-right: 0;
        }
    }
    &__num{
        display: block;
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 15px;
    }
    &__block{
        background-image: url(../img-compressed/block.jpg);
        border-radius: 29px;
        padding: 100px 40px;
        background-size: cover;
        &-list{
            list-style: none;
            color: white;
            font-size: 18px;
            font-weight: 700;
            list-style: none;
            padding: 0;
        }
        &-item{
            margin-bottom: 35px;
            display: flex;
            align-items: center;
            &::before{
                content: '';
                display: block;
                width: 31px;
                height: 31px;
                background-image: url(../img/check.svg);
                margin-right: 10px;
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}