.offer{
    background-image: url(../img-compressed/backround.png);
    background-repeat: no-repeat;
    background-position: right top;
    background-size: contain;
    // overflow: hidden;
    // height: 803px;
    position: relative;
    padding-top: 150px;
    &__img{
        position: absolute;
        &.front{
            width: 676px;
            height: auto;
            bottom: -120px;
            right: 0px;
        }
        &.back{
            width: 680px;
            height: auto;
            bottom: 0px;
            right: 70px;
        }
    }
    &__content{
        width: 45%;
    }
    &__decorate{
        position: absolute;
        left: -10px;
        bottom: 10px;
        font-size: 144px;
        opacity: .04;
        z-index: -1;
        text-transform: uppercase;
        font-weight: 700;
        margin: 0;
    }
}

.social{
    &__list{
        display: flex;
        align-items: center;
        list-style: none;
        padding: 0;
        margin-top: 150px;
    }
    &__link{
        font-size: 0;
        display: block;
        width: 50px;
        height: 50px;
        &.gmail{
            background-image: url(../img/gmail.svg);
        }
        &.ig{
            background-image: url(../img/ig.svg);
        }
        &.vk{
            background-image: url(../img/vk.svg);
        }
        &.wa{
            background-image: url(../img/wa.svg);
        }
    }
    &__item{
        margin-right: 30px;
        &:last-child{
            margin-right: 0;
        }
    }

    
}