@media screen and(max-width:1250px) {
    .container {
        max-width: 900px;
    }

    section {
        padding: 40px 0;
    }

    .offer {
        padding-top: 40px;
        background-position-x: 100%;

        &__img.front,
        &__img.back {
            width: 476px;
        }
    }

    .social {
        &__list {
            margin-top: 40px;
        }
    }

    .heading {
        &--big {
            font-size: 30px;
        }
    }

    .service {
        &__list {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .form {
        &__img {
            right: -200px;
        }

        &__form {
            width: 70%;
        }
    }

    .adv {
        .flex {
            flex-direction: column;
        }

        &__text {
            max-width: 100%;
        }

        &__block {
            padding: 40px;
            margin-top: 40px;
        }
    }

    .portfolio {
        &__list {
            grid-template-columns: repeat(3, 1fr);
        }
    }

}

@media screen and(max-width:950px) {
    .modal {
        width: 80%;

        .text {
            font-size: 14px;

            &--mini {
                font-size: 10px;
            }
        }

        .form__label-text {
            font-size: 14px;
        }
    }

    .slider {
        &__images {
            img {
                width: 300px;
                height: 305px;
            }
        }
    }

    .container {
        max-width: 700px;
    }

    .offer {
        background-position-x: 150%;

        &__content {
            position: relative;
            z-index: 2;
        }

        &__img {
            &.front {
                right: -124px;
            }

            &.back {
                right: 0;
            }
        }

        &__content {
            width: 65%;
        }
    }

    .social {
        &__list {
            z-index: 2;
            position: relative;
        }
    }

    .service {
        .buttons {
            a {
                margin-right: 0;
                width: 50%;
            }
        }

        &__heading {
            background: linear-gradient(90deg, rgba(0, 42, 147, 1) 0%, rgba(252, 70, 107, 0) 100%);

        }
    }

    .form {
        &__form {
            grid-template-columns: 1fr;
            position: relative;
            z-index: 2;
            width: 100%;
            grid-row-gap: 35px;
        }
    }

    .portfolio {
        &__list {
            grid-template-columns: 1fr 1fr;

        }

        &__link {
            font-size: 12px;
        }
    }

    .faq {
        &__list {
            max-width: 100%;
            z-index: 2;
            position: relative;
        }
    }

    .map {
        address {
            left: 20px;
        }
    }
}

@media screen and(max-width:750px) {
    .modal {
        width: 90%;
        padding: 20px;
    }

    header {

        nav,
        address {
            display: none !important;
        }
    }

    .burger {
        display: block;
    }

    .slider {
        flex-direction: column;
        top: 100%;
        left: 0;

        &__controll {
            order: 2;
        }

        &__images {
            width: 100%;

            img {
                width: 100vw
            }
        }
    }

    .filter {
        &__container {
            flex-direction: column;
        }
    }

    .map {
        height: 1000px;

        address {
            transform: none;
            top: unset;
            bottom: 0;
            left: 0;
            width: 100%;
            border-radius: 0;
        }
    }

    .container {
        max-width: 100%;
        padding: 0 15px;
    }

    .heading {
        &--big {
            font-size: 25px;
        }

        &--sect {
            font-size: 20px;
        }

        &--min {
            font-size: 16px;
        }
    }

    .text {
        font-size: 14px;

        &--big {
            font-size: 16px;
        }

        &--mini {
            font-size: 10px;
        }
    }

    .navigation {
        &__link {
            font-size: 14px;
        }
    }

    footer {
        .row {
            flex-direction: column;
        }

        a {
            font-size: 12px !important;
        }

        address {
            div {
                margin: 0 !important;
                align-items: center;
            }
        }
    }

    .offer {
        background-image: none;
        padding-bottom: 350px;

        &__content {
            width: 90%;
        }

        &__img {

            &.front,
            &.back {
                width: 500px;
            }
        }
    }

    .service {
        &__list {
            grid-template-columns: 1fr;
        }

        .buttons {
            flex-direction: column;

            a {
                width: 100%;
                margin-bottom: 5px;
            }
        }
    }

    .portfolio {
        &__list {
            grid-template-columns: 1fr 1fr;
        }
    }

    .adv {
        &__list {
            flex-direction: column;
        }

        &__block-item {
            font-size: 14px;

            &::before {
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
    }

    .faq {
        &__img {
            width: 400px;
            object-fit: contain;
        }

        &__item {
            padding-right: 50px;
        }
    }

    .article {
        &__content {
            p {
                font-size: 14px;
                max-width: 100%;
            }
        }

    }

    article {
        &.service {
            padding-top: 400px;
            &.nopad{
                padding-top: 100px;
            }
        }
    }

    .page {
        padding: 25px 0;

        .text {
            max-width: 100%;
        }
    }

    .price {
        &__item {
            padding: 10px;
        }

        &__data {
            display: grid;
            grid-template-columns: 1fr 1fr;

            .button--blue {
                grid-column-start: 1;
                grid-column-end: 3;
                margin-top: 15px;
                height: auto;
            }
        }

        &__show {
            margin-left: auto;
        }

        &__price.text {
            margin-left: 0;
        }

        &__description {
            max-width: 100%;
        }

    }
}

@media screen and(max-width:500px) {
    .portfolio {
        &__list {
            grid-template-columns: 1fr;
        }
    }
}