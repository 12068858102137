.map {
    padding: 0;
    height: 650px;
    position: relative;

    iframe {
        width: 100vw;
        height: 100%;
    }

    address {
        position: absolute;
        top: 50%;
        left: 20%;
        transform: translateY(-50%);
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 0px 6px 5px rgba(0, 0, 0, 0.16);
        border-radius: 17px;
        padding: 20px;
    }
    &__header{
        display: flex;
        align-items: center;
        font-style: normal;
        img{
            width: 58px;
            height: 60px;
            margin-right: 10px;
        }
    }
    &__item {
        a,p {
            color: var(--black);
            font-style: normal;
            margin: 0;
        }
        margin-bottom: 12px;
    }
    &__list{
        list-style: none;
        padding-left: 20px;
        margin-bottom: 40px;
    }
}