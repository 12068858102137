.faq{
    position: relative;
    background-image: url(../img-compressed/faqbg.png);
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: contain;
    overflow: hidden;
    min-height: 900px;
    &__img{
        position: absolute;
        right: 0;
        bottom: -105px;
    }
    .heading--min{
        margin: 0;
    }
    &__list{
        max-width: 65%;
        list-style: none;
        padding: 0;
    }
    &__item{
        padding:20px;
        background-color: var(--blue);
        margin-bottom: 25px;
        position: relative;
        cursor: pointer;
        &::after{
            position: absolute;
            top: 25px;
            right: 25px;
            width: 25px;
            height: 14px;
            background-image: url(../img/arrow.svg);
            content: '';
            transition: .3s ease;
        }
        &.active{
            &::after{
                transform: rotate(180deg);
            }
        }
    }
    &__answer{
        display: none;
        margin-top: 15px;
        line-height: 1.5;
        .button{
            display: block;
            margin-top: 15px;
        }
    }
}